import request from '../../utils/request';

/**
 * 资源管理
 * @param query
 * @returns {AxiosPromise}
 */

//查询数据
export const fetchData = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'System/Resource/find',
        method: 'get',
        params: query
    });
};

export const submitAdd = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'System/Resource/add',
        method: 'post',
        data: query
    });
};

export const removeData = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'System/Resource/remove',
        method: 'post',
        data: query
    });
};




